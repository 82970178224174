import React from "react";
import ThirdWeb from "../assets/thirdweb.png";
import ClickUp from "../assets/clickup.png";
import AWS from "../assets/aws.png";
import Azure from "../assets/azure.png";
import Mongodb from "../assets/mongo.png";
import Notion from "../assets/notion.png";
const CompanyMarquee = () => {
  return (
    <>
      <h2 className="mb-4 text-3xl font-bold tracking-tight text-center text-transparent font-heading sm:text-5xl bg-gradient-heading bg-clip-text">
        Powered by
      </h2>

      <div className="py-6 dark:bg-slate-100">
        <div className="relative overflow-hidden">
          <div className="flex items-center space-x-32 animate-marquee">
            <img src={ThirdWeb} alt="Thirdweb" className="w-auto h-8 sm:h-10" />
            <img src={AWS} alt="AWS" className="w-auto h-12 sm:h-16" />
            <img
              src={Azure}
              alt="Microsoft Azure"
              className="w-auto h-12 sm:h-16"
            />
            <img src={Mongodb} alt="MongoDB" className="w-auto h-12 sm:h-16" />
            <img src={Notion} alt="Notion" className="w-auto h-12 sm:h-16" />
            <img src={ClickUp} alt="ClickUp" className="w-auto h-12 sm:h-16" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyMarquee;
