import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export function SupportModal() {
  const PrivacyPolicy = () => (
    <div className="p-4 bg-gray-100 rounded-lg ">
      <div className="text-justify">
        <p className="text-justify ">
          OrbiMatrix is committed to protecting your privacy. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you visit our website{" "}
          <a href="https://orbimatrix.com/" className="text-blue-700">
            https://orbimatrix.com/
          </a>{" "}
          or use our services, including but not limited to web development, AI
          custom solutions, Web3 games, smart contract auditing, chatbot
          creation, and mobile app development. By accessing or using our
          website or services, you agree to the terms outlined in this Privacy
          Policy. If you do not agree with the terms, please do not access or
          use our services.
        </p>
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          1. Information We Collect
        </h4>
        <p>
          • Personal Information: When you contact us, sign up for an account,
          or use our services, you may provide us with information such as your
          name, email address, phone number, company name, or billing details.
        </p>
        <p>
          • Project Information: Details about your requirements for web
          development, AI solutions, Web3 games, or other services you request
        </p>
        <p>
          • Log Data: Information such as IP addresses, browser type, operating
          system, pages visited, and the date and time of your visit.
        </p>
        <p>
          • Cookies and Tracking Technologies: We use cookies to improve your
          browsing experience. These may include session cookies, preference
          cookies, and security cookies.
        </p>
        <p>
          We may collect data from third-party services such as analytics
          platforms, social media networks, or advertising partners to enhance
          our services.
        </p>
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          2. How We Use Your Information
        </h4>
        <p>We use the collected information for the following purposes:</p>
        <p>
          • To provide and manage our services, including Web3 games, AI tools,
          and development solutions.
        </p>
        <p>• To process payments and manage accounts.</p>
        <p>
          • To improve user experience and enhance our website functionality.
        </p>
        <p>
          • To send important updates, newsletters, or promotional content (you
          may opt-out of promotional emails at any time).
        </p>
        <p>• To analyze trends and monitor website performance.</p>
        <p>
          • To comply with legal obligations and enforce our terms of service.
        </p>
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          3. How We Share Your Information
        </h4>
        <p>
          We do not sell your personal information to third parties. However, we
          may share your data under the following circumstances:
        </p>
        <p>
          • Service Providers: Trusted partners who assist in providing services
          such as hosting, payment processing, analytics, or customer support.
        </p>
        <p>
          • Legal Obligations: When required to comply with legal processes,
          government requests, or applicable laws.
        </p>
        <p>
          • Business Transfers: In the event of a merger, acquisition, or sale
          of all or a portion of our assets, your information may be transferred
          to the new entity.
        </p>
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          4. Data Retention
        </h4>
        <p>
          We retain your personal data only as long as necessary to provide you
          with our services, fulfill our legal obligations, resolve disputes,
          and enforce our agreements.
        </p>
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          5. Security of Your Information
        </h4>
        <p>
          We implement industry-standard measures to protect your data against
          unauthorized access, disclosure, alteration, or destruction. However,
          no data transmission over the internet is completely secure. You
          acknowledge that you share your information at your own risk.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          6. Your Rights
        </h4>
        <p>
          Depending on your location, you may have the following rights
          concerning your personal data:
        </p>
        <p>
          • Access and Portability: Request access to the personal data we hold
          about you.
        </p>
        <p>
          • Correction: Request correction of inaccurate or incomplete data.
        </p>
        <p>
          • Deletion: Request the deletion of your personal data, subject to
          legal obligations.
        </p>
        <p>
          • Objection: Object to our processing of your data for certain
          purposes.
        </p>
        <p>• Opt-out: Unsubscribe from marketing communications.</p>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:contact@orbimatrix.com " className="text-blue-500">
            contact@orbimatrix.com
          </a>
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          7. Cookies and Tracking Technologies
        </h4>
        <p>
          We use cookies to personalize and enhance your experience on our
          website. You can manage or disable cookies through your browser
          settings, but this may affect certain functionalities of the site.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          8. Third-Party Links
        </h4>
        <p>
          Our website may include links to third-party websites. We are not
          responsible for the privacy practices or content of these external
          sites. Please review their privacy policies before providing any
          personal information.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          9. Children’s Privacy
        </h4>
        <p>
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect personal data from children. If you believe a
          child has provided us with their information, please contact us, and
          we will take steps to delete it.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          10. Changes to This Privacy Policy
        </h4>
        <p>
          We reserve the right to update this Privacy Policy to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of significant changes by posting an
          updated policy on this page with a revised 'Last Updated' date.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          11. Contact Us
        </h4>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data handling practices, please contact us:
        </p>
        <p>
          • By email:{" "}
          <a href="mailto:contact@orbimatrix.com " className="text-blue-500">
            contact@orbimatrix.com
          </a>{" "}
        </p>
        <p>
          {" "}
          • By visiting our website:{" "}
          <a href="https://orbimatrix.com/" className="text-blue-500">
            https://orbimatrix.com/
          </a>{" "}
        </p>
        <i>
          Thank you for trusting OrbiMatrix with your projects and data. Your
          privacy is our priority!
        </i>
      </div>
    </div>
  );

  const TermsOfService = () => (
    <div className="p-4 bg-gray-100 rounded-lg">
      <div className="text-justify">
        <p className="text-justify">
          Please read these terms and conditions carefully before using the
          OrbiMatrix website or its associated services.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Interpretation and Definitions
        </h4>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Interpretation
        </h4>
        <p>
          Words with their initial letter capitalized have specific meanings
          defined under these Terms. The following definitions apply equally to
          singular and plural forms.
        </p>{" "}
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Definitions
        </h4>
        <p>For the purposes of these Terms of Service:</p>{" "}
        <p>
          • "Affiliate" means any entity that controls, is controlled by, or is
          under common control with a party, where "control" refers to ownership
          of 50% or more of voting securities.
        </p>
        <p>
          • "Account" means the unique account created for You to access the
          Service or parts of the Service.
        </p>
        <p>• "Company", "We", "Us", or "Our" refers to OrbiMatrix.</p>
        <p>
          • "Country" will be updated once the Company is officially registered.
        </p>
        <p>
          • "Content" refers to any text, images, videos, or other media You
          post or share via the Service.
        </p>
        <p>
          • "Device" means any internet-connected device, such as a computer,
          smartphone, or tablet, used to access the Service.
        </p>
        <p>
          • "Feedback" means any suggestions, improvements, or feedback You
          provide to enhance the Service.
        </p>
        <p>
          • "GameX" or "GX" refers to OrbiMatrix's proprietary token used within
          the platform for transactions and rewards.
        </p>
        <p>
          • "Service" refers to the website, games, and other offerings provided
          by OrbiMatrix.
        </p>
        <p>
          • "Terms of Service" or "Terms" means this agreement governing your
          use of the Service.{" "}
        </p>
        <p>
          • "Website" refers to the OrbiMatrix website accessible at &nbsp;
          <a href="https://orbimatrix.com/" className="text-blue-500">
            {" "}
            https://orbimatrix.com/
          </a>
        </p>
        <p>
          • "You" refers to any individual or entity accessing or using the
          Service.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Acknowledgment
        </h4>
        <p>
          By accessing or using the Service, You agree to abide by these Terms
          and any future amendments. If You disagree with any portion of the
          Terms, you must not access the Service.
          <br />
          You affirm that You are at least 18 years of age. Individuals under 18
          are prohibited from using the Service.
          <br />
          Your access to and use of the Service is also governed by our Privacy
          Policy. By continuing to use our Service, You confirm You have read,
          understood, and agreed to both the Terms and the Privacy Policy
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          User Accounts
        </h4>
        <p>
          • You must ensure all information provided during registration is
          accurate, complete, and current. Inaccurate or incomplete information
          may result in account termination.
          <br />
          • You are responsible for maintaining the confidentiality of your
          password and for all activities under your account. Notify Us
          immediately if You suspect unauthorized use of Your account.
          <br />• Prohibited usernames include those impersonating others,
          infringing on trademark rights, or containing offensive or obscene
          language.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Content Policies
        </h4>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Your Right to Post Content
        </h4>
        <p>
          • By sharing Content on our platform, You grant Us a non-exclusive,
          worldwide, royalty-free license to use, display, reproduce, and
          distribute it.
          <br />• ou retain ownership of all Content You share but assume
          responsibility for its legality and appropriateness.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Content Restrictions
        </h4>
        <p>
          You may not upload or share Content that:
          <br />• Promotes illegal activities or violence.
          <br />• Contains hate speech or discriminatory language.
          <br />• Violates intellectual property rights or privacy laws.
          <br />• Is harmful, defamatory, or otherwise objectionable.
          <br />
          We reserve the right to remove such Content or limit access to our
          Service.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Content Backup
        </h4>
        <p>
          We attempt regular Content backups but cannot guarantee full data
          recovery in case of loss. Please maintain independent copies of
          important Content.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Intellectual Property
        </h4>
        <p>
          All elements of the Service, including its code, design, and
          proprietary features, remain the exclusive property of OrbiMatrix or
          its licensors. Unauthorized reproduction or distribution is strictly
          prohibited.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          GameX Token Usage
        </h4>
        <p>
          • Utility: GameX (GX) serves as the in-platform currency for rewards,
          transactions, and accessing premium features.
          <br />• Prohibition: GameX is not an investment vehicle and holds no
          value outside the platform. Users are prohibited from treating GameX
          as a security.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Termination
        </h4>
        <p>
          We may terminate or suspend Your account for breaches of these Terms.
          Upon termination, You lose access to the Service and related Content.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Liability Disclaimer
        </h4>
        <p>
          • The Service is provided "AS IS" without warranties of any kind. We
          do not guarantee uninterrupted, error-free operation.
          <br />• To the fullest extent permissible by law, OrbiMatrix disclaims
          liability for direct, indirect, incidental, or consequential damages
          arising from Service use
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Governing Law
        </h4>
        <p>
          These Terms are governed by the laws of the applicable jurisdiction.
          Disputes must first be resolved informally by contacting OrbiMatrix at
          &nbsp;
          <a href="mailto:contact@orbimatrix.com" className="text-blue-500">
            contact@orbimatrix.com
          </a>
          .
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Changes to Terms of Service
        </h4>
        <p>
          We reserve the right to amend these Terms at any time. Changes will be
          notified at least 30 days before taking effect. Continued use of the
          Service implies acceptance of updated Terms.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Contact Us
        </h4>
        <p>
          For inquiries or concerns about these Terms, please contact us:
          <br />• By visiting our contact page:{" "}
          <a href="mailto:contact@orbimatrix.com" className="text-blue-500">
            contact@orbimatrix.com
          </a>
        </p>
      </div>
    </div>
  );

  const Disclaimer = () => (
    <div className="p-4 bg-gray-100 rounded-lg">
      <div className="text-justify">
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Introduction
        </h4>
        <p className="text-justify">
          The information provided on the OrbiMatrix website nbsp;
          <a href="http://orbimatrix.com/" className="text-blue-500">
            https://orbimatrix.com/
          </a>{" "}
          is for general informational purposes only. All information on the
          Website is provided in good faith; however, we make no representation
          or warranty of any kind, express or implied, regarding the accuracy,
          adequacy, validity, reliability, availability, or completeness of any
          information on the Website
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          No Professional Advice
        </h4>
        <p>
          The Website may contain information related to blockchain
          technologies, AI solutions, Web3 games, mobile app development, and
          other services. Such content is provided solely for informational
          purposes and should not be considered as professional advice in any
          domain, including but not limited to legal, financial, or
          technological fields. Any reliance on such information is strictly at
          your own risk.
        </p>{" "}
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          External Links Disclaimer
        </h4>
        <p>
          The Website may contain links to external websites that are not
          provided or maintained by OrbiMatrix. We do not guarantee the
          accuracy, relevance, timeliness, or completeness of any information on
          these external websites.
        </p>
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Limitation of Liability
        </h4>
        <p>
          To the maximum extent permitted by law, under no circumstances shall
          OrbiMatrix, its employees, or affiliates be held liable for any loss
          or damage, including but not limited to indirect or consequential loss
          or damage, arising out of or in connection with the use of this
          Website or the information provided herein.
        </p>{" "}
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          Services Disclaimer
        </h4>
        <p>
          OrbiMatrix provides a range of services, including but not limited to
          web development, AI solutions, smart contract auditing, Web3 gaming,
          and mobile app development. The inclusion of such services on the
          Website does not guarantee their availability, performance, or
          suitability for your specific needs. All services are subject to the
          terms and conditions agreed upon during contractual engagements.
        </p>{" "}
        <h4 className="font-semibold text-blue-800 dark:text-theme-blue">
          Updates to This Disclaimer
        </h4>
        <p>
          OrbiMatrix reserves the right to modify or update this Disclaimer at
          any time without prior notice. It is your responsibility to review
          this Disclaimer periodically for any updates or changes.
        </p>{" "}
        <h4 className="font-semibold text-blue-600 dark:text-theme-blue">
          Contact Us
        </h4>
        <p>
          Contact Us If you have any questions about this Disclaimer, you can
          contact us via email at{" "}
          <a href="mailto:contact@orbimatrix.com" className="text-blue-500">
            contact@orbimatrix.com
          </a>{" "}
          or visit our Contact Us page on the Website
        </p>{" "}
      </div>
    </div>
  );

  const supportItems = [
    {
      name: "Privacy Policy",
      content: <PrivacyPolicy />,
    },
    {
      name: "Terms of Service",
      content: <TermsOfService />,
    },
    {
      name: "Disclaimer",
      content: <Disclaimer />,
    },
  ];

  const lostItems = [
    {
      name: "Sitemap",
      link: "https://orbimatrix.com/sitemap.xml",
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    body: "",
    isAcceptDecline: false,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [userAccepted, setUserAccepted] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [isLinkClicked, setIsLinkClicked] = useState(false);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

    if (!hasVisitedBefore) {
      localStorage.setItem("hasVisitedBefore", "true");
      setOpenModal(true);
      updateModalContent(supportItems[currentStep], true);
    } else {
      setIsFirstVisit(false);
    }
  }, [currentStep]);

  const updateModalContent = (item, showProgress) => {
    setModalContent({
      title: item.name,
      body: item.content,
      isAcceptDecline: showProgress,
    });
  };

  const progressPercentage = ((currentStep + 1) / supportItems.length) * 100;

  const handleAccept = () => {
    localStorage.setItem("userAcceptedTerms", "true");
    setUserAccepted(true);

    if (currentStep < supportItems.length - 1) {
      setCurrentStep(currentStep + 1);
      updateModalContent(supportItems[currentStep + 1], true);
    } else {
      setOpenModal(false);
    }
  };

  const handleDecline = () => {
    setUserAccepted(false);
    setOpenModal(false);
  };

  const handleNextStep = () => {
    if (currentStep < supportItems.length - 1) {
      setCurrentStep(currentStep + 1);
      updateModalContent(supportItems[currentStep + 1], true);
    } else {
      setOpenModal(false);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      updateModalContent(supportItems[currentStep - 1], true);
    }
  };

  const handleLinkClick = (item) => {
    setIsLinkClicked(true);
    setModalContent({
      title: item.name,
      body: item.content,
      isAcceptDecline: false,
    });
    setOpenModal(true);
  };

  return (
    <>
      <ul className="text-sm transition-all duration-500">
        {supportItems.map((item, index) => (
          <li key={index} className="mb-6">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleLinkClick(item);
              }}
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
            >
              {item.name}
            </a>
          </li>
        ))}
        {!isFirstVisit &&
          lostItems.map((item, index) => (
            <li key={index} className="mb-6">
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
              >
                {item.name}
              </a>
            </li>
          ))}
      </ul>
      {openModal && (
        <>
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-md"></div>
          <motion.div
            id="default-modal"
            tabIndex={-1}
            aria-hidden={!openModal}
            className="fixed top-0 z-50 flex items-start justify-center w-full h-screen max-w-2xl transform -translate-x-1/2 left-1/2 md:h-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative w-full max-w-2xl max-h-full p-4">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                <div className="flex items-center justify-between p-4 border-b rounded-t md:p-5 dark:border-gray-600">
                  <h3 className="text-2xl font-semibold text-transparent bg-gradient-heading bg-clip-text">
                    {modalContent.title}
                  </h3>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setOpenModal(false)}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>

                <div className="p-4 md:p-5">
                  {modalContent.isAcceptDecline && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                      <motion.div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${progressPercentage}%` }}
                        initial={{ width: 0 }}
                        animate={{ width: `${progressPercentage}%` }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                      ></motion.div>
                    </div>
                  )}
                </div>

                <div className="modal-scrollbar p-4 md:p-5 space-y-4 max-h-[calc(100vh-15rem)] overflow-y-auto">
                  <p className="text-base leading-relaxed dark:text-gray-500">
                    {modalContent.body}
                  </p>
                </div>

                {modalContent.isAcceptDecline ? (
                  <div className="flex items-center p-4 border-t border-gray-200 rounded-b md:p-5 dark:border-gray-600">
                    <button
                      onClick={handleAccept}
                      type="button"
                      className="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      I accept
                    </button>
                    <button
                      onClick={handleDecline}
                      type="button"
                      className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                    >
                      Decline
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-between p-4">
                    <button
                      onClick={handlePrevStep}
                      className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                    >
                      Back
                    </button>
                    <button
                      onClick={handleNextStep}
                      className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
}
